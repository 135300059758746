<template>
  <b-card>

    <b-modal
      id="merchant-settlement-account-modal"
      :title="`${updateId ? 'Update' : 'Add'} Merchant Settlement Account`"
      :ok-title="`${updateId ? 'Update' : 'Save'}`"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleMerchantSettlementAccountFormSubmit"
      @cancel="resetMerchantSettlementAccount"
      @close="resetMerchantSettlementAccount"
      @ok.prevent
    >
      <b-form @submit.prevent>
        <b-row>
          <!-- Bank -->
          <b-col cols="12">
            <b-form-group
              label="Bank"
              label-cols-md="4"
              :state="fintechIdState"
              invalid-feedback="Select a Bank"
            >
              <v-select
                v-model="formData.fintechId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Select Bank"
                :options="bankOptions"
                :reduce="val => val.id"
                :state="fintechIdState"
              />
            </b-form-group>
          </b-col>
          <!-- Chart of Account -->
          <b-col cols="12">
            <b-form-group
              label="Chart Of Account"
              label-cols-md="4"
              :state="coaState"
              invalid-feedback="Select Chart Of Account"
            >
              <v-select
                v-model="formData.coa"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="gl_name"
                placeholder="Select Chart Of Account"
                :options="coaOptions"
                :reduce="val => val.id"
                :state="coaState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Account Name"
              label-cols-md="4"
              :state="accountNameState"
              invalid-feedback="Account Name is Required"
            >
              <b-form-input
                v-model="formData.accountName"
                placeholder="Enter Account Name"
                :state="accountNameState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Account Number"
              label-cols-md="4"
              :state="accountNumberState"
              invalid-feedback="Account Number is Required"
            >
              <b-form-input
                v-model="formData.accountNumber"
                placeholder="Enter Account Number"
                :state="accountNumberState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Branch Name"
              label-cols-md="4"
              :state="branchState"
              invalid-feedback="Brunch is Required"
            >
              <b-form-input
                v-model="formData.branch"
                placeholder="Enter Branch Name"
                :state="branchState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Routing Number"
              label-cols-md="4"
              :state="routingState"
              invalid-feedback="Routing Number is Required"
            >
              <b-form-input
                v-model="formData.routing"
                placeholder="Enter Routing Number"
                :state="routingState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Country"
              label-cols-md="4"
              :state="countryState"
              invalid-feedback="Country is Required"
            >
              <v-select
                v-model="formData.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="short_name"
                placeholder="Select Country"
                :options="countryOptions"
                :reduce="val => val.id"
                :state="countryState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="City"
              label-cols-md="4"
              :state="cityState"
              invalid-feedback="City is Required"
            >
              <b-form-input
                v-model="formData.city"
                placeholder="Enter City"
                :state="cityState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Address"
              label-cols-md="4"
              :state="addressState"
              invalid-feedback="Address is Required"
            >
              <b-form-input
                v-model="formData.address"
                placeholder="Enter Address"
                :state="addressState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Postal Code"
              label-cols-md="4"
              :state="postalCodeState"
              invalid-feedback="Postal Code is Required"
            >
              <b-form-input
                v-model="formData.postalCode"
                placeholder="Enter Postal Code"
                :state="postalCodeState"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </b-modal>

    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Merchant Settlement Account
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          variant="outline-primary"
          style="text-transform: capitalize"
          @click="openModal"
        >
          Add Merchant Settlement Account
        </b-button>
      </b-form-group>
      <b-form-group class="pr-0 col-lg-4 col-md-4">
        <v-select
          v-model="institutionId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          placeholder="Select Institute"
          :options="institutionOptions"
          :reduce="val => val.id"
        />
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteRow(props.row)"
            >Delete</b-button>
          </span>
        </span>

        <span v-if="props.column.field === 'api_service_type'">
          {{ props.formattedRow[props.column.field] == 1 ? 'Inbound' : 'Outbound' }}
        </span>

        <span v-else-if="props.column.field === 'status'">
          {{ props.formattedRow[props.column.field] == 1 ? 'Active' : 'Inactive' }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','30','40','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div class="d-flex">
            <div class="mr-2">
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
            <div
              slot="table-actions"
              class="mt-1"
            >
              <b-button
                class="mr-1 px-1"
                variant="outline-primary"
                @click="refreshGrid()"
              >
                <RefreshCwIcon />
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- Audit Activity -->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BForm, BFormSelect, BPagination, BButton, BFormInput,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import Ripple from 'vue-ripple-directive'
import { RefreshCwIcon } from 'vue-feather-icons'
import store from '@/store/index'
import FemsToastrService from '@/@service/utils/FemsToastr.service'

import MerchantSettlementAccountService from '@/@service/api/accounting/fintech/MerchantSettlementAccount.service'
import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    VueGoodTable,
    BFormSelect,
    BPagination,
    BButton,
    vSelect,
    RefreshCwIcon,
    BLink,
    ActivityModal,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      updateId: null,
      columns: [
        {
          label: 'Bank Name',
          field: 'coa.gl_name',
        },
        {
          label: 'Branch Name',
          field: 'branch_name',
        },
        {
          label: 'Account Name',
          field: 'account_name',
          sortable: false,
        },
        {
          label: 'Account Number',
          field: 'account_number',
          sortable: false,
        },
        {
          label: 'Routing Number',
          field: 'routing_number',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      institutionId: null,
      formData: {
        serviceType: 1,
        categories: [],
        status: 1,
      },
      institutionOptions: [],
      fintechServiceOptions: [],
      bankOptions: [],
      coaOptions: [],
      countryOptions: [],

      fintechIdState: null,
      coaState: null,
      accountNameState: null,
      accountNumberState: null,
      branchState: null,
      routingState: null,
      countryState: null,
      cityState: null,
      addressState: null,
      postalCodeState: null,

      tag: 'merchant-settlement-account',
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    institutionId(newVal, oldVal) {
      if (!newVal) {
        this.rows = []
      }
      if (newVal && newVal !== oldVal) {
        this.getMerchantSettlementAccountGridData()
      }
    },
  },
  created() {
    this.getInstitutions()
  },
  methods: {
    getInstitutions() {
      MerchantSettlementAccountService.getInstitutes()
        .then(res => {
          this.institutionOptions = res.data.data
          this.institutionId = this.institutionOptions[0].id
          if (!this.institutionId) {
            FemsToastrService.error('Institution Id not found')
          }
          this.getMerchantSettlementAccountGridData()
        })
    },
    getBanks() {
      MerchantSettlementAccountService.getBanks()
        .then(res => {
          this.bankOptions = res.data.data
        })
    },
    getCoaList() {
      MerchantSettlementAccountService.getCOALists()
        .then(res => {
          this.coaOptions = res.data.data
        })
    },
    getCountries() {
      MerchantSettlementAccountService.getCountries()
        .then(res => {
          this.countryOptions = res.data.data
        })
    },
    getMerchantSettlementAccountService() {
      if (!this.institutionId) {
        FemsToastrService.error('Institution must be selected')
        return
      }
      MerchantSettlementAccountService.getServicesById(this.institutionId)
        .then(res => {
          this.fintechServiceOptions = res.data.data
        })
    },
    handleMerchantSettlementAccountFormSubmit() {
      if (this.updateId) {
        this.updateMerchantSettlementAccount()
      } else {
        this.saveMerchantSettlementAccount()
      }
    },
    validate() {
      this.fintechIdState = null
      this.coaState = null
      this.accountNameState = null
      this.accountNumberState = null
      this.branchState = null
      this.routingState = null
      this.countryState = null
      this.cityState = null
      this.addressState = null
      this.postalCodeState = null

      if (!this.formData.fintechId || (this.formData.fintechId && this.formData.fintechId.length < 1)) {
        this.fintechIdState = false
      }
      if (!this.formData.coa || (this.formData.coa && this.formData.coa.length < 1)) {
        this.coaState = false
      }
      if (!this.formData.accountName) {
        this.accountNameState = false
      }
      if (!this.formData.accountNumber) {
        this.accountNumberState = false
      }
      if (!this.formData.branch) {
        this.branchState = false
      }
      if (!this.formData.routing) {
        this.routingState = false
      }
      if (!this.formData.country || (this.formData.country && this.formData.country.length < 1)) {
        this.countryState = false
      }
      if (!this.formData.city) {
        this.cityState = false
      }
      if (!this.formData.address) {
        this.addressState = false
      }
      if (!this.formData.postalCode) {
        this.postalCodeState = false
      }
      if (this.fintechIdState !== false && this.coaState !== false && this.accountNameState !== false
        && this.accountNumberState !== false && this.branchState !== false
        && this.routingState !== false && this.countryState !== false && this.cityState !== false
        && this.addressState !== false && this.postalCodeState !== false) {
        return true
      }
      return false
    },

    saveMerchantSettlementAccount() {
      if (!this.validate()) {
        return
      }
      const params = {
        fintechId: this.formData.fintechId,
        institutionId: this.institutionId,
        coaId: this.formData.coa,
        accountName: this.formData.accountName,
        accountNumber: this.formData.accountNumber,
        branchName: this.formData.branch,
        routingNumber: this.formData.routing,
        countryId: this.formData.country,
        city: this.formData.city,
        address: this.formData.address,
        postalCode: this.formData.postalCode,
      }
      MerchantSettlementAccountService.store(params)
        .then(res => {
          if (res.data.status) {
            this.resetMerchantSettlementAccount()
            this.getMerchantSettlementAccountGridData()
            this.$bvModal.hide('merchant-settlement-account-modal')
            FemsToastrService.success(res?.data.message)
          } else {
            FemsToastrService.error(res?.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    updateMerchantSettlementAccount() {
      if (!this.validate()) {
        return
      }
      const params = {
        fintechId: this.formData.fintechId,
        institutionId: this.institutionId,
        coaId: this.formData.coa,
        accountName: this.formData.accountName,
        accountNumber: this.formData.accountNumber,
        branchName: this.formData.branch,
        routingNumber: this.formData.routing,
        countryId: this.formData.country,
        city: this.formData.city,
        address: this.formData.address,
        postalCode: this.formData.postalCode,
      }
      MerchantSettlementAccountService.update(params, this.updateId)
        .then(res => {
          if (res.data.status) {
            this.resetMerchantSettlementAccount()
            this.$bvModal.hide('merchant-settlement-account-modal')
            this.getMerchantSettlementAccountGridData()
            FemsToastrService.success(res?.data.message)
          } else {
            FemsToastrService.error(res?.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    getMerchantSettlementAccountGridData() {
      if (!this.institutionId) {
        this.rows = []
        FemsToastrService.error('Institution must be selected')
        return
      }
      MerchantSettlementAccountService.getAll(this.institutionId).then(res => {
        if (res.data.status) {
          this.rows = res.data.data
        } else {
          this.rows = []
        }
      })
        .catch(err => {
          this.rows = []
          FemsToastrService.error(err?.message)
        })
    },
    refreshGrid() {
      this.getMerchantSettlementAccountGridData()
    },
    resetMerchantSettlementAccount() {
      this.updateId = null
      this.formData = {}
      this.fintechIdState = null
      this.coaState = null
      this.accountNameState = null
      this.accountNumberState = null
      this.branchState = null
      this.routingState = null
      this.countryState = null
      this.cityState = null
      this.addressState = null
      this.postalCodeState = null
    },
    openModal() {
      if (!this.institutionId) {
        FemsToastrService.error('Institution must be selected')
        return
      }
      this.getBanks()
      this.getCoaList()
      this.getCountries()
      this.getMerchantSettlementAccountService()
      this.$bvModal.show('merchant-settlement-account-modal')
    },
    editRow(rowData) {
      this.updateId = rowData.id
      this.formData = {
        id: rowData.id,
        fintechId: rowData.fintech_id,
        coa: rowData.coa_id,
        accountName: rowData.account_name,
        accountNumber: rowData.account_number,
        branch: rowData.branch_name,
        routing: rowData.routing_number,
        country: rowData.country_id,
        city: rowData.city,
        address: rowData.address,
        postalCode: rowData.postal_code,
      }

      this.$bvModal.show('merchant-settlement-account-modal')
    },
    deleteRow(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          MerchantSettlementAccountService.delete(rowData.id)
            .then(res => {
              if (res.status && res.data.status) {
                this.resetMerchantSettlementAccount()
                this.getMerchantSettlementAccountGridData()
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: res.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else {
                FemsToastrService.error(res.data.message)
              }
            })
        }
      })
    },

    // Audit Activity
    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
