import axiosIns from '@/libs/axios'

export default {
  getInstitutes: (() => axiosIns.get('accounting/merchant-settlement-account/institute')),
  getAll: (Id => axiosIns.get(`accounting/merchant-settlement-account/read/${Id}`)),
  getBanks: (() => axiosIns.get('accounting/merchant-settlement-account/bank')),
  getCOALists: (() => axiosIns.get('accounting/merchant-settlement-account/coa')),
  getCountries: (() => axiosIns.get('accounting/merchant-settlement-account/country')),
  getServicesById: (id => axiosIns.get(`accounting/fintech-service/service/${id}`)),
  store: (payload => axiosIns.post('accounting/merchant-settlement-account', payload)),
  update: ((payload, id) => axiosIns.put(`accounting/merchant-settlement-account/${id}`, payload)),
  delete: (id => axiosIns.delete(`accounting/merchant-settlement-account/${id}`)),
}
